<template>
    <div v-if="pageAvailable" class="row">
        <div id="mainCard" class="col-md-8 framed partialPageCardLeft">
            <div class="card">
                <toggle-header :tabs="tabs" :viewOverride="currentView" :title="config.recoveryPlanName" :showTabsOverride="true" @currentView="onCurrentViewUpdate"/>
                <div v-if="currentView === progressView">
                    <lcp-table class="LCPcontent" :config="progressTableData" :id="progressView"/>
                    <highchart class="LCPcontent" :options="chartOptionsProgress" :series-options="chartSeriesProgress" key="progress"/>
                </div>
                <div v-if="currentView === assetLiabilityView">
                    <lcp-table class="LCPcontent" :config="assetLiabilityTableData" :id="assetLiabilityView"/>
                    <highchart class="LCPcontent" :options="chartOptionsAL" :series-options="chartSeriesAssetAndLiability" key="assetLiability"/>
                </div>
                <div v-if="currentView === paymentView">
                    <lcp-table class="LCPcontent" :config="assetLiabilityTableData" :id="assetLiabilityView"/>
                    <highchart v-if="chartSeriesPayment" class="LCPcontent" :options="chartOptionsPayment" :series-options="chartSeriesPayment" key="payment"/>
                    <vue-markdown v-else class="LCPcontent">{{config.noRecoveryPlanPaymentText}}</vue-markdown>
                </div>
            </div>
        </div>
        <div class="col-md-4 partialPageCardRight">
            <div v-show="blocked" id="blockedContent">
                <div class="text">
                    <span class="icon icon-Lock"/>
                    {{blockedContentText}}
                </div>
            </div>
            <div class="card">
                <div class="card-header-with-action">
                    <h1><br></h1>
                    <reset-inputs/>
                </div>
                <div class="col-md-12 input-container">
                    <input-wrapper :inputTemplate="input" :key="key" v-for="(input, key) in inputs"/>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="row">
        <div id="mainCard" class="col-md-12 framed">
            <div class="card">
                <div class="card-header">
                    <h1>{{config.recoveryPlanName}}</h1>
                </div>
                <vue-markdown class="LCPcontent" :source="pageNotAvailableText"/>
            </div>
        </div>
    </div>
</template>

<script>

import table from '@/components/common/tables/table.vue';
import barChart from '@/components/common/charts/barChart.js';
import lineChart from '@/components/common/charts/lineChart.js';

const chartOverrides = {
    chart: {
        defaultSeriesType: 'column'
    },
    tooltip: {
        formatter () {
            let s = '<b>' + Math.round(this.x) + '</b><table>';
            s += '<tr style=\'color:' + this.color + '\'><td style=\'padding-right: 10px;\'>' + this.series.name + ': </td><td style=\'text-align: right\'><b>' + this.y.toAmountString() + '</b></td></tr>';
            s += '</table>';
            return s;
        }
    },
    xAxis: [{
        labels: {
            formatter () {
                return Math.round(this.value);
            }
        }
    }]
};

export default {
    data () {
        return {
            currentView: null,
            blocked: true
        };
    },
    created () {
        this.pageAvailable = this.$staticStore.state.recoveryplan.pageAvailable;
        this.pageNotAvailableText = this.$staticStore.state.recoveryplan.pageNotAvailableText;
        this.config = this.$staticStore.state.recoveryplan.config;

        if (!this.pageAvailable) return;

        this.inputs = this.$staticStore.state.recoveryplan.inputControls;
        const contributionsInputConfig = this.inputs[this.config.contributionsInputId].config;
        contributionsInputConfig.decimalPlacesOverride = window.LCP.fn.getDpsRequiredToShowAllValues(contributionsInputConfig.options, contributionsInputConfig.displayType);

        this.chartOptionsProgress = lineChart.chartOptions(window.LCP.con.AMOUNT);
        this.chartOptionsAL = lineChart.chartOptions(window.LCP.con.AMOUNT);

        if (this.config.progressAvailable) {
            const currentAssets = this.config.assetHistory.data[this.config.assetHistory.data.length - 1];
            const currentLiability = this.config.liabilityHistory.data[this.config.liabilityHistory.data.length - 1];
            const currentSurplus = this.getSurplus(currentAssets, currentLiability);
            const currentProjectedSurplus = this.config.expectedPositionHistory.data[this.config.expectedPositionHistory.data.length - 1];
            const currentProgress = currentSurplus - currentProjectedSurplus;

            this.progressTableData = this.getProgressTableData(currentSurplus, currentProjectedSurplus, currentProgress);
            this.expectedPositionHistorySeries = lineChart.getSeriesFromDailyData(this.config.expectedPositionHistoryDisplayName, this.config.expectedPositionColour, this.config.expectedPositionHistory);
        }

        const headerArray = ['', 'Target date', 'Position at target date', 'Remaining contributions'];
            
        this.assetLiabilityTableCurrentRowData = [
            table.getCellDefinition('Current'),
            table.getCellDefinition(this.config.recoveryPlanEndDate.ToDateString()),
            table.getCellDefinition(this.getSurplusText(this.config.initialProjectedPosition), this.config.initialProjectedPosition.getChangeColour(true)),
            table.getCellDefinition(this.config.initialRemainingContributions.toAmountString())
        ];
            
        this.assetLiabilityTableHeaderRow = table.getRowFromValues(headerArray);

        const dailySurplusData = this.getDailySurplusData(this.config.assetHistory.data, this.config.liabilityHistory.data);
        this.assetHistorySeries = lineChart.getSeriesFromDailyData(this.config.assetHistoryDisplayName, this.config.assetColour, this.config.assetHistory);
        this.liabilityHistorySeries = lineChart.getSeriesFromDailyData(this.config.liabilityHistoryDisplayName, this.config.liabilityColour, this.config.liabilityHistory);
        this.surplusHistorySeries = lineChart.getSeries(this.config.deficitHistoryDisplayName, this.config.deficitColour, dailySurplusData, this.config.assetHistory.startDate, this.config.assetHistory.endDate);

        this.chartSeriesProgress = [];
        if (this.expectedPositionHistorySeries !== undefined) this.chartSeriesProgress.push(this.expectedPositionHistorySeries);
        this.chartSeriesProgress.push(this.surplusHistorySeries);

        this.chartOptionsPayment = barChart.chartOptions(chartOverrides, window.LCP.con.AMOUNT);
        this.chartOptionsPayment.xAxis[0].title.text = `Year from ${this.config.calculationDate.ToDayAndMonthString()}`;

        this.paymentView = 'Recovery plan payments';
        this.progressView = 'Progress vs plan';
        this.assetLiabilityView = 'Projected position';

        this.includePaymentChart = this.config.includePaymentChart;

        this.blockedContentText = this.includePaymentChart
            ? `These options are available in the ${this.paymentView} and ${this.assetLiabilityView} views`
            : `These options are only available in ${this.assetLiabilityView} view`;

        this.tabs = [];
        if (this.config.progressAvailable) this.tabs.push(this.progressView);
        this.tabs.push(this.assetLiabilityView);
        if (this.includePaymentChart) this.tabs.push(this.paymentView);

        this.currentView = this.$store.state.recoveryplan.selectedTab != null ? this.$store.state.recoveryplan.selectedTab : null;
    },
    methods: {
        getSurplus (assetValue, liabilityValue) {
            return assetValue - liabilityValue;
        },
        getDailySurplusData (assetValues, liabilityValues) {
            const output = [];
            for (let i = 0; i < assetValues.length; i++) {
                output.push(this.getSurplus(assetValues[i], liabilityValues[i]));
            };
            return output;
        },
        getProgressText (value) {
            if (value > 0) return value.toAmountString() + ' ahead';
            return Math.abs(value).toAmountString() + ' behind';
        },
        getSurplusText (value) {
            if (value > 0) return Math.abs(value).toAmountString() + ' surplus';
            return Math.abs(value).toAmountString() + ' deficit';
        },
        onCurrentViewUpdate (view) {
            this.currentView = view;
            this.$store.state.recoveryplan.selectedTab = this.currentView;
            this.blocked = view === this.progressView;
        },
        getProgressTableData (currentSurplus, currentProjectedSurplus, currentProgress) {
            const headerRow = table.getRowFromValues(['', 'Projected from ' + this.config.recoveryPlanStartDate.ToDateString(), 'Actual', 'Progress']);

            const cellContentByRow = {};
            cellContentByRow.progress = [
                table.getCellDefinition('Position at ' + this.config.calculationDate.ToDateString()),
                table.getCellDefinition(currentProjectedSurplus.toAmountString()),
                table.getCellDefinition(currentSurplus.toAmountString()),
                table.getCellDefinition(this.getProgressText(currentProgress), currentProgress.getChangeColour(true))
            ];

            return table.getTableConfig(headerRow, cellContentByRow);
        },
        getPaymentChartSeries (chartData) {
            if (chartData.length === 0) return null;

            return barChart.getSeriesCollectionFromChartSeriesProjectedXyDoubleCollection(chartData, 'Payments');
        }
    },
    computed: {
        dynamicOutput () {
            return this.$store.state.recoveryplan.dynamicOutput;
        },
        assetLiabilityTableData () {
            const projectedSurplus = this.getSurplus(this.dynamicOutput.projectedAssetValues.yValues[this.dynamicOutput.projectedAssetValues.yValues.length - 1], this.dynamicOutput.projectedLiabilityValues.yValues[this.dynamicOutput.projectedLiabilityValues.yValues.length - 1]);

            const cellContentByRow = {};

            cellContentByRow.chosen = [
                table.getCellDefinition('Chosen', null, null, true),
                table.getCellDefinition(this.dynamicOutput.endOfRecoveryPlanDate.ToDateString(), null, null, true),
                table.getCellDefinition(this.getSurplusText(projectedSurplus), projectedSurplus.getChangeColour(true), null, true),
                table.getCellDefinition(this.dynamicOutput.remainingContributions.toAmountString(), null, null, true)
            ];

            cellContentByRow.current = this.assetLiabilityTableCurrentRowData;

            return table.getTableConfig(this.assetLiabilityTableHeaderRow, cellContentByRow);
        },
        assetProjectionSeries () {
            const series = lineChart.getSeriesFromXYData('Projected ' + this.config.assetHistoryDisplayName, this.config.assetColour, this.dynamicOutput.projectedAssetValues.xValues, this.dynamicOutput.projectedAssetValues.yValues, true);
            series.dashStyle = 'ShortDash';
            series.showInLegend = false;
            return series;
        },
        chartSeriesPayment () {
            const series = [];
            const existingPayments = this.config.existingRecoveryPlanPayments;

            if (existingPayments != null) {
                for (let i = 0; i < existingPayments.length; i++) {
                    series.push(existingPayments[i]);
                }
            }

            if (this.dynamicOutput.chosenPaymentSeries != null) {
                for (let i = 0; i < this.dynamicOutput.chosenPaymentSeries.length; i++) {
                    series.push(this.dynamicOutput.chosenPaymentSeries[i]);
                }
            }

            return this.getPaymentChartSeries(series);
        },
        liabilityProjectionSeries () {
            const series = lineChart.getSeriesFromXYData('Projected ' + this.config.liabilityHistoryDisplayName, this.config.liabilityColour, this.dynamicOutput.projectedLiabilityValues.xValues, this.dynamicOutput.projectedLiabilityValues.yValues, true);
            series.dashStyle = 'ShortDash';
            series.showInLegend = false;
            return series;
        },
        chartSeriesAssetAndLiability () {
            const output = [];
            if (this.liabilityHistorySeries) output.push(this.liabilityHistorySeries);
            if (this.assetHistorySeries) output.push(this.assetHistorySeries);
            output.push(this.liabilityProjectionSeries);
            output.push(this.assetProjectionSeries);
            return output;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import 'src/assets/scss/base.scss';

#blockedContent {
    background: lightgrey;
    border-radius: 6px;
    height: 100%;
    position: absolute;
    width: calc(100% - 22.5px);
    z-index: 6;
    @include responsive ('md') {
        width: calc(100% - 30px);
    }
    .text {
        font-size: 20px;
        padding: 30px;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        span {
            display: block;
            font-size: 60px;
            margin-bottom: 10px;
        }
        @include responsive ('sm') {
            font-size: $fontSizeStandard;
        }
    }
}

</style>
